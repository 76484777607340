import React, { useEffect, useState } from 'react'
import * as s from "./main.module.css"

import { gsap } from "gsap/dist/gsap";

import { withpath } from '../utils/index.js'

import { Waypoint } from 'react-waypoint';

import Art1 from './art1/index'
import Art2 from './art2/index'
import Art3 from './art3/index'



let tl, tlback



const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  let hdistance = -100
  let hdistancetxt = -60
  let imgscale = .65

  const [anim1active, setAnim1Active] = useState(false);
  const [anim2active, setAnim2Active] = useState(false);
  const [anim3active, setAnim3Active] = useState(false);

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
  });

	useEffect(() => {

    let imgscale = .65

    
    let headerimg = document.querySelector(`#headerimg`);
    let prehead = document.querySelector(`#prehead`);
    let head = document.querySelector(`#head`);
    let intro = document.querySelector(`#intro`);

    gsap.killTweensOf(headerimg,prehead,head,intro)
    gsap.set(headerimg, {opacity: 0, scale: 1.1})
    gsap.set([prehead,head,intro], {opacity: 0, translateY: 16})
    
    let htl = gsap.timeline({ paused: false });
      htl.to(headerimg, {opacity: 1, scale: 1, duration: 1.8, transformOrigin: '50% 50%', ease: `power2.out` })
      htl.to([prehead,head,intro], {opacity: 1, translateY: 0, duration: .8, stagger: .2,  ease: `power2.out` }, '<.5')


    
    let g1arrow1 = document.querySelector(`#g1-arrow1`);
    let g1txt1 = document.querySelector(`#g1-txt1`);
    let g1txt2 = document.querySelector(`#g1-txt2`);
    let g1txt3 = document.querySelector(`#g1-txt3`);
    let g1img1 = document.querySelector(`#g1-img1`);
    

    gsap.killTweensOf([g1arrow1,g1txt1,g1txt2,g1txt3,g1img1])
    gsap.set([g1arrow1,g1txt1,g1txt2,g1txt3], {opacity: 0})
    gsap.set(g1img1, {opacity: 0, scale: imgscale})

    
    let g2arrow1 = document.querySelector(`#g2-arrow1`);
    let g2txt1 = document.querySelector(`#g2-txt1`);
    let g2txt2 = document.querySelector(`#g2-txt2`);
    let g2txt3 = document.querySelector(`#g2-txt3`);
    let g2img1 = document.querySelector(`#g2-img1`);

    gsap.killTweensOf([g2arrow1,g2txt1,g2txt2,g2txt3,g2img1])
		gsap.set([g2arrow1,g2txt1,g2txt2,g2txt3], {opacity: 0})
		gsap.set(g2arrow1, {opacity: 0})
    gsap.set(g2img1, {opacity: 0, scale: imgscale})
    


    let g3sq1 = document.querySelector(`#g3-sq1`);
    let g3sq2 = document.querySelector(`#g3-sq2`);
    let g3sq3 = document.querySelector(`#g3-sq3`);
    let g3icon1 = document.querySelector(`#g3-icon1`);
    let g3icon2 = document.querySelector(`#g3-icon2`);
    let g3icon3 = document.querySelector(`#g3-icon3`);
    let g3txt1 = document.querySelector(`#g3-txt1`);
    let g3txt2 = document.querySelector(`#g3-txt2`);
    let g3txt3 = document.querySelector(`#g3-txt3`);

    gsap.killTweensOf([g3sq1,g3sq2,g3sq3,g3icon1,g3icon2,g3icon3,g3txt1,g3txt2,g3txt3])
		gsap.set([g3sq1,g3sq2,g3sq3,g3icon1,g3icon2,g3icon3,g3txt1,g3txt2,g3txt3], {opacity: 0})

  },[])


	function enter1(){

    setAnim1Active(true);
    
    let g1arrow1 = document.querySelector(`#g1-arrow1`);
    let g1txt1 = document.querySelector(`#g1-txt1`);
    let g1txt2 = document.querySelector(`#g1-txt2`);
    let g1txt3 = document.querySelector(`#g1-txt3`);
    let g1img1 = document.querySelector(`#g1-img1`);

    gsap.killTweensOf([g1arrow1,g1txt1,g1txt2,g1txt3,g1img1])
    gsap.set(g1arrow1, {opacity: 0, translateX: hdistance})
    gsap.set(g1img1, {opacity: 0, scale: imgscale})
    gsap.set([g1txt1,g1txt2,g1txt3], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g1arrow1, {opacity: 1, translateX: 0, duration: 1.8, ease: `power2.out` })
      tl.to(g1img1, {opacity: 1, scale: 1, duration: 1, translateX: 0, translateY: 0, transformOrigin: 'bottom left', ease: `power2.out` }, '<.2')
      tl.to([g1txt1, g1txt2, g1txt3], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.5')
    
	}

	function leave1(){

    setAnim1Active(false);

    let g1arrow1 = document.querySelector(`#g1-arrow1`);
    let g1txt1 = document.querySelector(`#g1-txt1`);
    let g1txt2 = document.querySelector(`#g1-txt2`);
    let g1txt3 = document.querySelector(`#g1-txt3`);
    let g1img1 = document.querySelector(`#g1-img1`);

    let tl = gsap.timeline({ paused: false });
      tl.to([g1arrow1,g1img1,g1txt1,g1txt2,g1txt3], {opacity: 0, duration: 1.8, stagger: .2, ease: `power2.out` })

  }


	function enter2(){

    setAnim2Active(true);

    let g2arrow1 = document.querySelector(`#g2-arrow1`);
    let g2txt1 = document.querySelector(`#g2-txt1`);
    let g2txt2 = document.querySelector(`#g2-txt2`);
    let g2txt3 = document.querySelector(`#g2-txt3`);
    let g2img1 = document.querySelector(`#g2-img1`);

    gsap.killTweensOf([g2arrow1,g2txt1,g2txt2,g2txt3,g2img1])
    gsap.set(g2arrow1, {opacity: 0, translateX: hdistance})
    gsap.set(g2img1, {opacity: 0, scale: imgscale})
    gsap.set([g2txt1,g2txt2,g2txt3], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g2arrow1, {opacity: 1, translateX: 0, duration: 1.8, ease: `power2.out` })
      tl.to(g2img1, {opacity: 1, scale: 1, duration: 1, translateX: 0, translateY: 0, transformOrigin: 'bottom left', ease: `power2.out` }, '<.2')
      tl.to([g2txt1, g2txt2, g2txt3], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.5')

	}

	function leave2(){

    setAnim2Active(false);

    let g2arrow1 = document.querySelector(`#g2-arrow1`);
    let g2txt1 = document.querySelector(`#g2-txt1`);
    let g2txt2 = document.querySelector(`#g2-txt2`);
    let g2txt3 = document.querySelector(`#g2-txt3`);
    let g2img1 = document.querySelector(`#g2-img1`);

    let tl = gsap.timeline({ paused: false });
      tl.to([g2arrow1,g2img1,g2txt1,g2txt2,g2txt3], {opacity: 0, duration: 1.8, stagger: .2, ease: `power2.out` })
	}


  function enter3(){

    setAnim3Active(true);

    let g3sq1 = document.querySelector(`#g3-sq1`);
    let g3sq2 = document.querySelector(`#g3-sq2`);
    let g3sq3 = document.querySelector(`#g3-sq3`);
    let g3icon1 = document.querySelector(`#g3-icon1`);
    let g3icon2 = document.querySelector(`#g3-icon2`);
    let g3icon3 = document.querySelector(`#g3-icon3`);
    let g3txt1 = document.querySelector(`#g3-txt1`);
    let g3txt2 = document.querySelector(`#g3-txt2`);
    let g3txt3 = document.querySelector(`#g3-txt3`);

    gsap.killTweensOf([g3sq1,g3sq2,g3sq3,g3icon1,g3icon2,g3icon3,g3txt1,g3txt2,g3txt3])
		gsap.set([g3txt1,g3txt2,g3txt3], {opacity: 0, translateX: hdistancetxt})
		gsap.set([g3icon1,g3icon2,g3icon3], {opacity: 0, scale: .7, translateX: -16})
		gsap.set([g3sq1,g3sq2,g3sq3], {opacity: 0, scale: .5, transformOrigin: '50% 50%'})
    
    let tl = gsap.timeline({ paused: false });
      tl.to([g3sq1, g3sq2, g3sq3], {opacity: 1, scale: 1, duration: 1, ease: `power2.out` })
      tl.to([g3icon1,g3txt1,g3icon2,g3txt2,g3icon3,g3txt3], {opacity: 1, translateX: 0, scale: 1, transformOrigin: '50% 50%', duration: 1.3, stagger: .15, ease: `power2.out` }, '<.2')
    
  }

  function leave3(){

    setAnim3Active(false);


    let g3sq1 = document.querySelector(`#g3-sq1`);
    let g3sq2 = document.querySelector(`#g3-sq2`);
    let g3sq3 = document.querySelector(`#g3-sq3`);
    let g3icon1 = document.querySelector(`#g3-icon1`);
    let g3icon2 = document.querySelector(`#g3-icon2`);
    let g3icon3 = document.querySelector(`#g3-icon3`);
    let g3txt1 = document.querySelector(`#g3-txt1`);
    let g3txt2 = document.querySelector(`#g3-txt2`);
    let g3txt3 = document.querySelector(`#g3-txt3`);

    gsap.killTweensOf([g3sq1,g3sq2,g3sq3,g3icon1,g3icon2,g3icon3,g3txt1,g3txt2,g3txt3])

    let tl = gsap.timeline({ paused: false });
      tl.to([g3sq1,g3sq2,g3sq3,g3icon1,g3icon2,g3icon3,g3txt1,g3txt2,g3txt3], {opacity: 0, translateX: 0, duration: 1.8, stagger: .15, ease: `power2.out` })
  }
  

	return (
		<section className={s.article} >

      {/* Hero Block */}
			<div 
				className={s.headerbg} 
			>
        <div id="headerimg" className={`${s.headerbgimg} ${s.headerbgimgDes}`} style={{backgroundImage: `url(${withpath(`/img/hero_bg.jpg`)})` }}/>
        <div id="headerimgMob" className={`${s.headerbgimg} ${s.headerbgimgMob}`} style={{backgroundImage: `url(${withpath(`/img/hero_bg_mob.jpg`)})` }}/>
			</div>
      <div className={s.headerbox}>
        <div id="prehead" className={s.prehead} dangerouslySetInnerHTML={{__html: data.prehead }} />
        <div id="head" className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
        <div id="intro" className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
        <div className={s.descLine} />
      </div>


      {/* Intro */}
			<div className={s.box} data-id={0}>

				<div className={s.tbox} data-id={0}>
					<div data-id={0}>
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
            
            <Waypoint 
                onEnter={(p) => {enter1()}} 
                onLeave={(p) => {leave1()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art1}>
                <Art1 isAnimated={anim1active} />
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />

            <Waypoint 
                onEnter={(p) => {enter2()}} 
                onLeave={(p) => {leave2()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art2}>
                <Art2 isAnimated={anim2active} />
              </div>
            </Waypoint>


            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={4} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={7} />
            <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={8} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={9} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={10} />
          

            <Waypoint 
                onEnter={(p) => {enter3()}} 
                onLeave={(p) => {leave3()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art3}>
                <h5 className={s.imghead}>Top three ESG issues for investors</h5>
                <Art3 isAnimated={anim3active} />
                <p className={s.imgcaption}>Source: HSBC Asset Management sustainable investing survey, 2021</p>
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={11} />
					</div>
				</div>

			</div>

		</section>
)}

export default Main
